<template>
  <div>

    <section
      id="knowledge-base-content"
      class="pb-5"
    >

      <b-row>

        <div
          v-if="!planos.length "
        >

          <b-col
            cols="12"
            style="padding: 0px;"
          >
            <b-card
              :no-body="true"
              class="text-left p-3"
              style="overflow: hidden;"
            >
              
              <b-card-body>

                <div style="position: absolute; top: 0; right: 0;z-index:0;">
                  <img src="@/assets/images/bg/bg.png" alt="Ícone Favoritos" />
                </div>

                <div style="position: relative;z-index:2;">
                  <b-card-title class="text-white">
                    <h1 class="content-header-title">Bem-vindo aos <b>planos de assinaturas</b></h1>
                  </b-card-title>
                  <b-card-text class="my-2">
                    <h6>
                      <b>Aqui é onde você encontrará todos os planos de assinaturas que temos para você.</b>
                      <br>No momento, não estamos com os nossos planos disponíveis. Volte aqui em alguns dias, escolha seu plano e aproveite 100% o Mercado Hit.       
                    </h6>
                  </b-card-text>

                </div>

              </b-card-body>

            </b-card>
          </b-col>

          </div>

        <b-col
          v-for="plano in planos"
          xl="4" cols="12"
          v-if="planos.length > 0"
        >
          <b-card 
            class="justify-content-center align-items-center text-center"
          >

            <b-card-body>

              <div>
                <b-badge
                  variant="dark"
                  pill
                >
                  {{ plano.nome }}
                </b-badge>
              </div>

              <div>

                <sup 
                class="font-weight-bold text-primary" 
                style="top: -1.5rem;left: 0;font-size: 1.1rem !important;"
                >
                  R$
                </sup>
                <span class="font-weight-bolder text-primary" style="font-size: 3.5rem;">
                  {{ Math.floor(plano.valor / 100) }}
                </span>
                <sup 
                class="font-medium-1 font-weight-bold text-primary"
                style="top: -1.5rem;left: -.5rem;font-size: 1.1rem !important;"
                >
                  ,{{ ('0' + Math.floor((plano.valor / 10) % 10) * 10).slice(-2) }}
                </sup>
                
              </div>

              <div>
                <span class="font-weight-bold">
                  <b-icon
                    font-size="15"
                    icon="wallet2"
                    color="#000"
                    style="margin-right: 3px;"
                  />
                  {{plano.credito}} créditos
                </span>
              </div>

              <b-button
                class="mt-1"
                variant="primary"
                :to="{ name: 'financeiro-assinatura-recarregar', params: { id: plano.id } }"
              >
                Selecionar Plano
              </b-button>
              
            </b-card-body>
            
          </b-card>
        </b-col>

        

      </b-row>

    </section>

  </div>
</template>

<script>
import {
  BRow, BImg, BCol, BCard, BCardBody, BCardFooter, BForm, BFormGroup, BInputGroup, BCardTitle, BFormInput, BCardText, BInputGroupPrepend, BButton, BBadge, BLink, BSpinner,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCardTitle,
    BButton,
    BBadge,
    BLink,
    BSpinner,
  },
  data() {
    return {

      planos: [],
      carregamentoApi: false,
     
    }
  },
  created() {
    this.planosLista()
  },
  methods: {

    planosLista() {
      this.carregamentoApi = true

      useJwt.assinaturaPlanosLista({
        // page: this.page,
        // size: this.size,
        // sort: this.sort,
      })
        .then(response => {

          this.planos = response.data.content

          // console.log(this.planos)
       
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
//@import '@fracto/vue-credit-card/';

.card.selecionarPagamento:hover {
  box-shadow: 0 0 5px 1px rgb(254 152 86 / 40%) !important;
  border-color: #ff7300 !important;
}


</style>
